.wrapper {
	background-image: url("../../../public/images/hepatit/bg_hepatit.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;

	@media (max-width: 991px) {
		background-position-x: 40%;
	}
}

.container {
	max-width: 1440px;
	padding: 0px 75px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@media (max-width: 1300px) {
		padding: 0px 40px;
	}

	@media (max-width: 1140px) {
		padding: 0px 25px;
	}

	@media (max-width: 991px) {
		flex-direction: column;
		gap: 40px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 155px 0;
	flex-basis: 60%;
	padding-right: 20px;

	@media (max-width: 991px) {
		padding-bottom: 0;
		padding-right: 25%;
		padding-top: 56px;
	}

	@media (max-width: 768px) {
		padding-right: 15%;
	}
	@media (max-width: 560px) {
		padding-right: 0;
	}
}

.title {
	color: #000;
	font-family: "Rubik";
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 57.6px */

	@media (max-width: 1300px) {
		font-size: 44px;
	}

	@media (max-width: 1140px) {
		font-size: 40px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
	}

	@media (max-width: 560px) {
		font-size: 24px;
	}
}

.desc {
	color: #03071e;
	font-family: "Open Sans";
	font-size: 24px;
	font-weight: 400;
	line-height: 140%;
	margin: 30px 0px 60px 0px;

	@media (max-width: 1300px) {
		font-size: 22px;
	}

	@media (max-width: 1140px) {
		font-size: 20px;
	}

	@media (max-width: 560px) {
		font-size: 18px;
	}

	& strong {
		color: #28609d;
		font-weight: 700;
	}
}

.image {
	flex-basis: 41%;
	align-self: flex-end;
	position: relative;
	height: 100%;

	@media (max-width: 991px) {
		min-height: 330px;
		min-width: 345px;
		align-self: center;
	}

	@media (max-width: 360px) {
		min-width: 320px;
	}

	img {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-height: 100%;
		object-fit: cover;
	}
}
