.own_body {
	padding: 60px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media (max-width: 991px) {
		padding: 0;
	}
}

.title {
	align-self: flex-start;
	color: #03071e;
	font-family: "Rubik";
	font-size: 48px;
	font-weight: 600;
	line-height: 140%; /* 67.2px */
	margin-bottom: 30px;

	@media (max-width: 1300px) {
		font-size: 44px;
	}

	@media (max-width: 1140px) {
		font-size: 40px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
	}

	@media (max-width: 560px) {
		font-size: 24px;
	}

	@media (max-width: 425px) {
		font-size: 23px;
	}

	@media (max-width: 370px) {
		font-size: 19px;
	}
}

.own_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	width: 100%;
}

.title_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	width: 100%;
}

.title_info {
	align-self: flex-start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 60px;
	color: #03071e;
	font-size: 16px;
	font-weight: 600;
	line-height: 140%; /* 22.4px */

	@media (max-width: 991px) {
		font-size: 14px;
	}
}

.author {
	& span:last-child {
		font-weight: 400;
	}
}

.title_image {
	position: relative;
	width: 100%;
	padding-bottom: 45%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content_text1,
.content_text3,
.content_text5,
.content_text6 {
	color: #000;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 991px) {
		font-size: 16px;
	}
}

.content_text2,
.content_text4 {
	color: #000;
	font-size: 18px;
	font-style: italic;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 991px) {
		font-size: 16px;
	}
}

.second_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	padding: 0px 110px;
	width: 100%;

	@media (max-width: 991px) {
		padding: 0;
	}
}

.second_image {
	position: relative;
	width: 100%;
	padding-bottom: 77%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.desc_img {
	align-self: flex-start;
	color: #000;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%; /* 22.4px */

	@media (max-width: 991px) {
		font-size: 14px;
	}
}

.title_image3 {
	padding: 0px 110px;
	color: #000;
	text-align: center;
	font-size: 18px;
	font-style: italic;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 991px) {
		font-size: 16px;
		padding: 0;
	}
}

.third_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	padding: 0px 110px;
	width: 100%;

	@media (max-width: 991px) {
		padding: 0;
	}
}

.third_image {
	position: relative;
	width: 100%;
	padding-bottom: 67%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.desc_img3 {
	align-self: flex-start;
	color: #000;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%; /* 22.4px */

	@media (max-width: 991px) {
		font-size: 14px;
	}
}

.own_social {
	display: flex;
	justify-content: space-between;
	align-self: center;
	width: 100%;
	padding: 60px 0px;

	@media (max-width: 991px) {
		flex-direction: column;
		justify-content: center;
		gap: 40px;
		padding: 40px 0px;
	}
}

.btn_back {
	align-self: center;
	max-height: 50px;
	border-radius: 100px;
	outline: 1px solid #588cc4;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #588cc4;
	font-size: 24px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	padding: 8px 86px;
	transition: all 0.4s ease-in-out;

	@media (max-width: 991px) {
		align-self: center;
		width: 70%;
		padding: 12px 0px;
		background: #588cc4;
		color: #fefefe;
	}

	@media (max-width: 560px) {
		font-size: 16px;
		padding: 14px 0px;
		width: 95%;
	}

	&:hover {
		color: #fefefe;
		background-color: #588cc4;
		transition: all 0.4s ease-in-out;

		@media (max-width: 991px) {
			color: #588cc4;
			background-color: transparent;
		}
	}
}

