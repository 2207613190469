.nav_list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;

	@media (max-width: 1400px) {
		gap: 10px;
	}

	@media (max-width: 1300px) {
		font-size: 14px;
	}

	@media (max-width: 1140px) {
		font-size: 12px;
		gap: 5px;
	}

		@media (max-width: 1024px) {
		gap: 3px;
	}
}


