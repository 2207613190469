.wrapperNews {
	flex: 1;
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 110px;
		background-image: url("../../../public/images/stories/bg_up.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 991px) {
			height: 40px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 110px;
		background-image: url("../../../public/images/stories/bg_down.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 991px) {
			height: 40px;
		}
	}
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;
	gap: 60px;
	padding: 70px 185px;

	@media (max-width: 1300px) {
		padding: 70px 120px;
	}

	@media (max-width: 1140px) {
		padding: 70px 80px;
	}

	@media (max-width: 991px) {
		padding: 40px 60px;
		gap: 0;
	}

	@media (max-width: 768px) {
		padding: 40px 25px;
	}
}

.title {
	color: #03071e;
	font-family: "Rubik";
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 57.6px */

	@media (max-width: 1300px) {
		font-size: 44px;
	}

	@media (max-width: 1140px) {
		font-size: 40px;
	}

	@media (max-width: 768px) {
		font-size: 34px;
	}

	@media (max-width: 560px) {
		font-size: 24px;
	}

	@media (max-width: 374px) {
		padding: 0px 10px;
		font-size: 20px;
	}
}