.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.container {
	flex: 1;
	max-width: 1440px;
	padding: 15px 75px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #03071e;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	@media (max-width: 1300px) {
		padding: 15px 40px;
	}

	@media (max-width: 1140px) {
		padding: 15px 25px;
	}

	@media (max-width: 991px) {
		justify-content: flex-start;
	}
}
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav_btn {
	color: #fefefe;
	border-radius: 100px;
	background-color: #588cc4;
	padding: 9px 14px;
	cursor: pointer;
	outline: 1px solid #588cc4;
	transition: all 0.4s ease-in-out;

	@media (max-width: 1300px) {
		font-size: 14px;
	}

	@media (max-width: 1140px) {
		font-size: 12px;
		padding: 7px 12px;
	}

	@media (max-width: 991px) {
		font-size: 16px;
		padding: 9px 14px;
		margin-left: auto;
	}

	&:hover {
		color: #588cc4;
		background-color: #fefefe;
		transition: all 0.4s ease-in-out;
	}
}

.burger_btn {
	display: flex;
	justify-content: center;
	align-items: center;
}
