.person_link {
	flex: 0 1 31.78%;

	@media (max-width: 1439px) {
		flex: 0 1 31.2%;
	}
}

.person_body {
	position: relative;
	transition: all 0.3s ease-in-out;

	& {
		transition: all 0.3s ease-in-out;
	}

	&:hover .image {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		transition: all 0.3s ease-in-out;
	}

	&:hover .image:after {
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		transition: all 0.3s ease-in-out;
	}

	&:hover .info_box {
		height: 25%;
		transition: all 0.3s ease-in-out;
	}
}

.image {
	position: relative;
	padding-bottom: 71%;
	transition: all 0.3s ease-in-out;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: all 0.3s ease-in-out;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.info_box {
	display: flex;
	flex-direction: column;
	gap: 4px;
	line-height: 140%;
	color: #fefefe;
	position: absolute;
	left: 16px;
	bottom: 0;
	width: 95%;
	height: 0;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.info_name {
	font-size: 20px;
	font-weight: 700;
}

.info_position {
	font-size: 18px;
	font-weight: 600;
}

//*** Mobil ***//

.personMob_body {
	flex: 0 1 45%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 768px) {
		flex: 0 1 80%;
	}

	@media (max-width: 560px) {
		flex: 0 1 100%;
	}
}

.title_mob {
	align-self: flex-start;
	color: #000;
	font-size: 18px;
	font-weight: 600;
	line-height: 140%; /* 25.2px */

	@media (max-width: 991px) {
		font-size: 14px;
	}

	@media (max-width: 768px) {
		font-size: 18px;
	}
}

.image_mob {
	position: relative;
	padding-bottom: 71%;
	width: 100%;
	margin: 8px 0px 20px 0px;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.btn_read {
	border-radius: 100px;
	outline: 1px solid #588cc4;
	background: #588cc4;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fefefe;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	width: 90%;
	padding: 14px 0px;
	transition: all 0.4s ease-in-out;

	@media (max-width: 560px) {
		font-size: 16px;
		width: 95%;
	}

	&:hover {
		color: #588cc4;
		background-color: #fefefe;
		transition: all 0.4s ease-in-out;
	}
}
