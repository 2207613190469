.wrapperSectionContacts {
	display: flex;
	justify-content: center;
	background-image: url("../../../public/images/contacts/bg_contacts.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
	}

	@media (max-width: 560px) {
		padding: 56px 0;
	}
}

.title {
	align-self: flex-start;
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
		padding: 0px 25px;
	}
}

.content_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 87px;
	width: 100%;

	@media (max-width: 991px) {
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}
}

.contacts_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 25px 0px;
	gap: 37px;

	@media (max-width: 991px) {
		order: 2;
	}

	@media (max-width: 560px) {
		padding: 0px 25px;
		align-self: flex-start;
	}
}

.info_title {
	color: #03071e;
	font-size: 20px;
	font-weight: 600;
	line-height: 140%; /* 28px */
	margin-bottom: 10px;

	@media (max-width: 560px) {
		font-size: 18px;
	}
}

.info_desc {
	color: #03071e;
	font-size: 16px;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.image {
	position: relative;
	flex: 0 1 66%;
	padding-bottom: 28.7%;

	@media (max-width: 991px) {
		width: 100%;
		padding-bottom: 50%;
	}

	@media (max-width: 560px) {
		padding-bottom: 85.4%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}