.wrapperTeam {
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 140px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
		gap: 80px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
	}
}

.box_title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	max-width: 740px;
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: 560px) {
		font-size: 14px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
	}
}

.desc {
	color: rgba(3, 7, 30, 0.3);
	font-size: 18px;
	font-weight: 600;
	line-height: 140%; /* 25.2px */

	@media (max-width: 560px) {
		font-size: 16px;
	}
}

.box_team {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
	width: 100%;
}