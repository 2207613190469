.wrapper_question {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #03071e;
	padding: 20px 0;
}

.help_btnContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 20px;
	cursor: pointer;
}

.help_question {
	color: #03071e;
	font-size: 20px;
	font-weight: 600;
	line-height: 140%; /* 28px */

	@media (max-width: 768px) {
		font-size: 18px;
	}
}

.help_icon {
	border-radius: 50%;
	border: 1px solid #28609d;
	background-color: #fefefe;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	transition: all 0.3s ease-in-out;

	&.active {
		background-color: #28609d;
		transition: all 0.3s ease-in-out;
	}

	& svg {
		transform: rotate(-45deg);
		transition: all 0.3s ease-in-out;

		& path {
			transition: all 0.3s ease-in-out;
		}
	}

	&.active svg {
		transform: rotate(0deg);
		transition: all 0.3s ease-in-out;

		& path {
			stroke: #fefefe;
			transition: all 0.3s ease-in-out;
		}
	}
}

.help_wrapper {
	@media (max-width: 991px) {
		align-self: flex-start;
	}
}
