.wrapperQuestions {
	display: flex;
	justify-content: center;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
	}
}

.box_title {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: 560px) {
		font-size: 14px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
	}
}

.content_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 60px;
	max-height: 671px;

	@media (max-width: 991px) {
		align-self: flex-start;
		gap: 0;
		max-height: 100%;
		width: 100%;
	}
}

.box_picture {
	padding: 85px 0px;

	@media (max-width: 991px){
		display: none;
	}
}

.box_questions {
	max-width: 630px;
	overflow: scroll;
	height: 100%;

	@media (max-width: 991px) {
		overflow: auto;
		max-width: 100%;
		width: 100%;
	}
}
