.wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fefefe;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: hidden;
	z-index: 10;
}

.container {
	// background-color: #fefefe;
	flex: 1;
	height: 98%;
	min-width: 310px;
	max-width: 400px;
	padding: 50px 16px 0px 24px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
}

.header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	position: relative;
	padding-bottom: 16px;

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 60px;
		height: 1px;
		width: 100%;
		background-color: rgba(3, 7, 30, 0.3);
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	line-height: 140%;
	width: 50%;
}

.burger_btn {
	margin-left: auto;
	align-self: flex-start;
}

.lang {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 17px 16px 17px 8px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 60px;
		height: 1px;
		width: 100%;
		background-color: rgba(3, 7, 30, 0.3);
	}
}

.lang_title {
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;
}

.lang_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	font-weight: 400;
	line-height: 140%;
}

.contact {
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;
	padding: 17px 16px 17px 8px;
	position: relative;
}

.support_btn {
	line-height: 140%;
	color: #fefefe;
	background-color: #588cc4;
	padding: 14px 0;
	border-radius: 100px;
	// margin-right: auto;
	align-self: center;
	width: 100%;
	text-align: center;
}
