.link_details {
	padding: 40px 0px;
	border-bottom: 1px solid #28609d;
	width: 100%;
	transition: all 0.3s ease-in-out;

	@media (max-width: 991px) {
		max-width: 70%;
	}

	@media (max-width: 768px) {
		max-width: 80%;
	}

	@media (max-width: 560px) {
		max-width: 100%;
	}

	&:hover .box_details{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		background-color: #FEFEFE;
		transition: all 0.3s ease-in-out;
	}

	&:hover .image:after{
		background-color: rgba(0, 0, 0, 0.3);
		transition: all 0.3s ease-in-out;
	}
}

.box_details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	height: 270px;
	transition: all 0.3s ease-in-out;

	@media (max-width: 991px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		height: 457px;
	}
}

.content_box {
	flex: 0 1 48%;
	display: flex;
	flex-direction: column;
	height: 100%;
	transition: all 0.3s ease-in-out;

	@media (max-width: 991px) {
		order: 2;
		height: 233px;
		flex: 0 1 auto;
	}
}

.title {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 1140px){
		font-size: 30px;
	}

	@media (max-width: 991px){
		font-size: 18px;
		margin-top: 10px;
	}
}

.desc_body {
	flex: 1 1 auto;
	margin: 30px 0px 14px 0px;
	color: #03071e;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	overflow: hidden;
	text-overflow: ellipsis;

	@media (max-width: 991px){
		font-size: 16px;
		margin: 10px 0px;
	}
}

.date {
	color: #03071e;
	font-size: 16px;
	font-weight: 600;
	line-height: 140%; /* 22.4px */
}

.list {
	padding-left: 5px;
	font-size: 18px;
}

.image {
	flex: 1 1 52%;
	position: relative;
	height: 270px;
	transition: all 0.3s ease-in-out;

	@media (max-width: 991px) {
		flex: 1 1 auto;
		height: 224px;
		width: 100%;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: all 0.3s ease-in-out;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.title_boxMob {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	margin-bottom: 10px;
}

.dateMob {
	font-weight: 600;
}

.authorMob {
	& span:first-child {
		font-weight: 600;
	}
}