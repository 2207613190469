.box_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media (max-width: 991px) {
		padding: 0;
	}
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;
	margin-bottom: 10px;
	cursor: pointer;

	@media (max-width: 560px) {
		font-size: 14px;
		margin-bottom: 8px;
	}
}

.subtitle,
.title_props {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
		line-height: 140%;
	}
}

.desc {
	color: #03071e;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	margin-top: 30px;

	@media (max-width: 560px) {
		font-size: 16px;
	}
}

.box_props {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.props_list {
	color: #000000;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.props_item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 6px;

	& span:first-child {
		font-family: "Rubik";
		font-size: 20px;
		font-weight: 500;
		line-height: 120%; /* 24px */
		// width: max-content;

		@media (max-width: 991px) {
			font-size: 18px;
		}
	}

	& span:last-child {
		font-size: 18px;
		font-weight: 400;
		line-height: 140%; /* 25.2px */
	}
}
