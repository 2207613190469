.wrapperAdvateges {
	display: flex;
	justify-content: center;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
	}

	@media (max-width: 560px) {
		gap: 40px;
	}
}

.box_title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	max-width: 740px;
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: 560px) {
		font-size: 14px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
	}
}

.desc {
	color: rgba(3, 7, 30, 0.3);
	font-size: 18px;
	font-weight: 600;
	line-height: 140%; /* 25.2px */

	@media (max-width: 560px) {
		font-size: 16px;
	}
}

.box_advateges {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;

	@media (max-width: 991px) {
		flex-wrap: wrap;
	}

	@media (max-width: 768px) {
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: center;
	}

	@media (max-width: 560px) {
		gap: 40px;
	}
}

.column {
	flex: 0 1 25%;

	@media (max-width: 991px) {
		flex: 0 1 47%;
	}
}

.item {
	padding: 23px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #03071e;
	text-align: center;
	transition: all 0.3s ease-in-out;

	@media (max-width: 768px) {
		padding: 23px 42px;
	}

	&:hover img {
		transform: scale(1.03);
		transition: all 0.3s ease-in-out;
	}

	img {
		transition: all 0.3s ease-in-out;
	}
}

.item_img {
	margin-bottom: 20px;
}

.item_title {
	font-family: "Rubik";
	font-size: 20px;
	font-weight: 500;
	line-height: 120%;
	margin-bottom: 4px;
}

.item_desc {
	font-weight: 400;
	line-height: 140%;
}
