.link_article {
	transition: all 0.3s ease-in-out;

	&:hover .box_article{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		transition: all 0.3s ease-in-out;
	}

	&:hover .image:after{
		background-color: rgba(0, 0, 0, 0.3);
		transition: all 0.3s ease-in-out;
	}

	&:hover .content_article{
		background-color: #FEFEFE;
		transition: all 0.3s ease-in-out;
	}
}

.box_article {
	display: flex;
	flex-direction: column;
	height: 455px;
	transition: all 0.3s ease-in-out;
}

.image {
	flex: 1 1 auto;
	position: relative;
	height: 224px;
	transition: all 0.3s ease-in-out;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: all 0.3s ease-in-out;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content_article {
	height: 231px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	color: #03071e;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
	transition: all 0.3s ease-in-out;
}

.title_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	margin-bottom: 10px;
}

.date {
	font-weight: 600;
}

.author {
	& span:first-child {
		font-weight: 600;
	}
}

.title {
	font-size: 20px;
	font-weight: 600;
	margin: 10px 0px;

	@media (max-width: 1140px) {
		font-size: 18px;
	}
}

.desc_body {
	overflow: hidden;
	text-overflow: ellipsis;
}

.text {
	font-size: 14px;
}

.list {
	padding-left: 5px;
	font-size: 14px;
}