.wrapperStories {
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 7%;
		background-image: url("../../../public/images/stories/bg_up.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (min-width: 1441px) {
			height: 11%;
		}

		@media (max-width: 991px) {
			height: 3%;
		}

		@media (max-width: 768px) {
			height: 1%;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 7%;
		background-image: url("../../../public/images/stories/bg_down.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 991px) {
			height: 3%;
		}

		@media (max-width: 768px) {
			height: 1%;
		}
	}
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;
	gap: 60px;
	padding: 60px 75px 210px 75px;

	@media (max-width: 1300px) {
		padding: 60px 40px 210px 40px;
	}

	@media (max-width: 1140px) {
		padding: 60px 25px 210px 25px;
	}

	@media (max-width: 991px) {
		padding: 40px 25px 80px 25px;
		gap: 40px;
	}

	@media (max-width: 425px) {
		padding: 40px 0px 80px 0px;
	}
}

.title {
	color: #03071e;
	font-family: "Rubik";
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 57.6px */

	@media (max-width: 1300px) {
		font-size: 44px;
	}

	@media (max-width: 1140px) {
		font-size: 40px;
	}

	@media (max-width: 768px) {
		font-size: 34px;
	}

	@media (max-width: 560px) {
		font-size: 24px;
	}

	@media (max-width: 374px) {
		padding: 0px 10px;
		font-size: 20px;
	}
}

.stories_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width: 991px) {
		justify-content: center;
		gap: 40px;
	}

	@media (max-width: 425px) {
		padding: 0px 25px;
	}
}
