.wrapperSupport {
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
	position: relative;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 80px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 80px 40px;
	}

	@media (max-width: 1140px) {
		padding: 60px 25px;
		gap: 60px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 0;
		justify-content: center;
	}
}

.info_body {
	flex: 0 1 44%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 60px;
	padding: 58px 0px;

	@media (max-width: 991px) {
		padding: 0;
		flex: 0 1 100%;
	}
}

.support_image {
	flex: 0 1 45%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;

	@media (max-width: 991px) {
		display: none;
	}
}
