.partner_img {
	min-width: 150px;
	min-height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	
	@media (max-width: 991px) {
		// width: 150px;
		// height: 150px;
		width: auto;
		height: auto;
	}

	img {
		@media (max-width: 991px) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}