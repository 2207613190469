.btn_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	gap: 80px;
	position: relative;
}

.prevBtn,
.nextBtn {
	padding: 13px 13px 12px 12px;
	border-radius: 50%;
	border: 1px solid #28609d;
	transition: all 0.3s ease-in-out;

	&:hover {
		border: 1px solid #588cc4;
		background-color: #588cc4;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		svg path {
			fill: #fefefe;
			transition: all 0.3s ease-in-out;
		}
	}

	& {
		svg path {
			transition: all 0.3s ease-in-out;
		}
	}
}

.prevBtn {
	transform: rotate(180deg);
}
