.wrapperLink {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.titleLink {
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;

	@media (max-width: 991px) {
		font-size: 16px;
	}
}

.boxLink {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;

	@media (max-width: 425px) {
		gap: 9px;
	}
}

.itemLink {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	& a {
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			@media (max-width: 360px){
				width: 30px;
				height: 30px;
			}
		}
	}
}
