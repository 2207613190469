.wrapperModal {
	position: fixed;
	background-color: rgba(3, 7, 30, 0.7);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
}

.containerModal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 750px;
}
