.wrapperSupport {
	max-width: 710px;
	padding: 20px 40px;
	border-radius: 20px;
	background-color: #efefef;
	position: relative;

	@media (max-width: 991px) {
		max-width: 344px;
		padding: 16px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("../../../public/images/support/bg_support.svg");
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 20px;

		@media (max-width: 991px) {
			background-image: url("../../../public/images/support/bg_support_mob.svg");
		} 
	}
}

.box_props {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.props_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title_props {
	color: #03071e;
	font-family: "Rubik";
	font-size: 24px;
	font-weight: 600;
	line-height: 140%; /* 33.6px */

	@media (max-width: 991px) {
		font-size: 22px;
	}
}

.btn_support {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ce5349;
	border-radius: 4px;
	padding: 5px;
	z-index: 2;
	cursor: pointer;

	& svg {
		@media (max-width: 991px) {
			width: 11px;
			height: 11px;
		}
	}
}

.props_list {
	color: #000000;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.props_item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 6px;

	& span:first-child {
		font-family: "Rubik";
		font-size: 20px;
		font-weight: 500;
		line-height: 120%; /* 24px */
		// width: max-content;

		@media (max-width: 991px) {
			font-size: 18px;
		}
	}

	& span:last-child {
		font-size: 18px;
		font-weight: 400;
		line-height: 140%; /* 25.2px */
	}
}
