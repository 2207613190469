.wrapperNews {
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
	}
}

.header_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box_title {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;

	@media (max-width: 560px) {
		flex: 0 1 65%;
	}
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: 560px) {
		font-size: 14px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
		font-weight: 600;
	}
}

.subtitle2 {
	color: #588cc4;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 700;
	line-height: 140%; /* 25.2px */
	text-decoration-line: underline;

	@media (max-width: 560px) {
		align-self: flex-end;
		font-size: 14px;
		padding-bottom: 4px;
		max-height: 24px;
		overflow: hidden;
		text-overflow: clip;
		text-align: end;
	}
}

.box_news {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media (max-width: 991px) {
		width: 70%;
	}

	@media (max-width: 768px) {
		width: 90%;
	}

	@media (max-width: 560px) {
		width: 100%;
	}
}
