.wrapperOrganization {
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 140px;
	padding: 100px 75px;

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
		gap: 80px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
		flex-direction: column;
		justify-content: center;
	}
}

.image {
	position: relative;
	min-height: 450px;
	min-width: 410px;

	@media (max-width: 991px) {
		min-height: 378px;
		min-width: 344px;
	}

	@media (max-width: 360px) {
		min-width: 270px;
		min-height: 290px;
	}

	img {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		// max-height: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.box_content {
	padding: 71px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media (max-width: 991px) {
		padding: 0;
	}
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;
	margin-bottom: 10px;

	@media (max-width: 560px) {
		font-size: 14px;
		margin-bottom: 8px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
		line-height: 140%;
	}
}

.desc {
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	margin: 30px 0px 20px 0px;

	@media (max-width: 560px) {
		font-size: 16px;
	}
}

.btn_org {
	color: #fefefe;
	border-radius: 100px;
	background-color: #588cc4;
	font-size: 18px;
	padding: 8px 59px;
	cursor: pointer;
	outline: 1px solid #588cc4;
	transition: all 0.4s ease-in-out;

	@media (max-width: 991px) {
		align-self: center;
	}

	@media (max-width: 560px) {
		font-size: 16px;
		padding: 8px 0px;
		width: 100%;
		text-align: center;
	}

	&:hover {
		color: #588cc4;
		background-color: #fefefe;
		transition: all 0.4s ease-in-out;
	}
}
