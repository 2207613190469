.empty_body {
	// flex: 0 1 100%;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	padding: 40px 0;
}

.title {
	align-self: flex-start;
	color: #03071e;
	text-align: center;
	font-family: "Rubik";
	font-size: 48px;
	font-weight: 600;
	line-height: 140%; /* 67.2px */

	@media (max-width: 1300px) {
		font-size: 44px;
	}

	@media (max-width: 1140px) {
		font-size: 40px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
	}

	@media (max-width: 560px) {
		font-size: 24px;
	}

	@media (max-width: 425px) {
		font-size: 23px;
	}

	@media (max-width: 370px) {
		font-size: 19px;
	}
}

.btn_back {
	align-self: center;
	max-height: 50px;
	border-radius: 100px;
	outline: 1px solid #588cc4;
	background: #588cc4;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fefefe;
	font-size: 24px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	padding: 8px 86px;
	transition: all 0.4s ease-in-out;

	@media (max-width: 991px) {
		align-self: center;
		width: 50%;
		padding: 12px 0px;
	}

	@media (max-width: 560px) {
		font-size: 16px;
		padding: 12px 0px;
		width: 70%;
	}

	&:hover {
		color: #588cc4;
		background-color: #fefefe;
		transition: all 0.4s ease-in-out;
	}
}
