.wrapper {
	position: relative;
}

.selected {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	line-height: 140%;
	padding: 10px 10px 10px 10px;
	cursor: pointer;

	& span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.lang {
	padding: 0px 8px 0px 4px;
}

.arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	transition: all 0.3s ease-in-out;

	& svg {
		transition: all 0.3s ease-in-out;

		& path {
			transition: all 0.3s ease-in-out;
		}
	}

	&.up svg {
		transform: rotate(180deg);
		transition: all 0.3s ease-in-out;
	}
}

.dropdown {
	position: absolute;
	margin-top: 14px;
	background-color: #fefefe;
	width: 100%;
	overflow: hidden;
}

.dropdownItem {
	padding: 4px 10px;
	display: flex;
	justify-content: flex-start;
	gap: 4px;
	align-items: center;
	font-size: 12px;
	line-height: 140%;
	cursor: pointer;
	position: relative;
	z-index: 2;
	transition: all 0.3s ease-in-out;

	&:hover {
		color: #fefefe;
		transition: all 0.3s ease-in-out;
		background-color: #588cc4;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

//***Mobil***//

.mobileLang {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 4px;
	gap: 4px;

	&:not(:last-child) {
		border-right: 1px solid rgba(3, 7, 30, 0.30);
	}

	&.active {
		font-weight: 700;
	}
}
