.box_answers {
	padding: 20px 56px 0px 20px;
}

.text,
.text_strong {
	color: #000;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 768px) {
		font-size: 16px;
	}
}

.text_strong {
	font-weight: 600;
}

.list {
	list-style: disc;
	padding-left: 30px;
	color: #03071e;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 768px) {
		font-size: 16px;
	}
}
