.subWrapper {
	position: absolute;
	left: 0;
	top: 20px;
	margin-top: 25px;
	z-index: 3;
	background-color: #fefefe;
	overflow: hidden;
}

.subItem {
	margin-top: 10px;
	padding: 4px 14px;
	transition: all 0.3s ease-in-out;

	&:hover {
		color: #fefefe;
		transition: all 0.3s ease-in-out;
		background-color: #588cc4;
	}
}

.mainItem {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 0px 14px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&.active {
		color: #588cc4;
		transition: all 0.3s ease-in-out;

		&::after {
			width: calc(100% - 25px);
			transition: all 0.3s ease-in-out;
		}

		& .arrow svg path {
			stroke: #588cc4;
			transition: all 0.3s ease-in-out;
		}
	}

	&::after {
		content: "";
		position: absolute;
		left: 12px;
		top: 30px;
		height: 4px;
		width: 0;
		background-color: #588cc4;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		color: #588cc4;
		transition: all 0.3s ease-in-out;

		&::after {
			width: calc(100% - 25px);
			transition: all 0.3s ease-in-out;
		}

		& .arrow svg path {
			stroke: #588cc4;
			transition: all 0.3s ease-in-out;
		}
	}

	&:first-child .subWrapper {
		width: 145%;
	}

	&:nth-child(2) .subWrapper {
		width: 113%;
	}

	&:nth-child(3) .subWrapper {
		width: 245%;
	}
}

.arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	transition: all 0.3s ease-in-out;

	& svg {
		transition: all 0.3s ease-in-out;

		& path {
			transition: all 0.3s ease-in-out;
		}
	}

	&.up svg {
		transform: rotate(180deg);
		transition: all 0.3s ease-in-out;
	}
}

.item {
	padding: 0px 13px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: 12px;
		top: 30px;
		height: 4px;
		width: 0;
		background-color: #588cc4;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		color: #588cc4;
		transition: all 0.3s ease-in-out;

		&::after {
			width: calc(100% - 25px);
			transition: all 0.3s ease-in-out;
		}
	}
}

//***BugerMenu***//

.mainItemMob {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;
	padding: 17px 0px 17px 8px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&.active {
		&::after {
			width: 0;
			transition: all 0.3s ease-in-out;
		}
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 60px;
		height: 1px;
		width: 100%;
		background-color: rgba(3, 7, 30, 0.3);
		transition: all 0.3s ease-in-out;
	}

	&:nth-child(3) .subWrapperMob {
		padding-right: 100px;
		margin-top: 35px;
		gap: 15px;
	}

	&:nth-child(4) .subWrapperMob {
		padding-right: 20px;
    padding-bottom: 20px;
	}
}

.arrowMob {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	transition: all 0.3s ease-in-out;

	& svg {
		transition: all 0.3s ease-in-out;
	}

	&.up svg {
		transform: rotate(180deg);
		transition: all 0.3s ease-in-out;
	}
}

.subWrapperMob {
	position: absolute;
	left: 0;
	top: 20px;
	margin-top: 50px;
	z-index: 3;
	background-color: #fefefe;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}

.subItemMob {
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	padding-left: 8px;
}

.itemMob {
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;
	padding: 17px 16px 17px 8px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 60px;
		height: 1px;
		width: 100%;
		background-color: rgba(3, 7, 30, 0.3);
		transition: all 0.3s ease-in-out;
	}
}
