.person_body {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 75px;

	@media (max-width: 1140px) {
		gap: 40px;
	}

	@media (max-width: 991px) {
		gap: 16px;
		flex-direction: column;
	}
}

.image {
	flex: 0 1 44%;
	position: relative;
	padding-bottom: 31.8%;

	@media (max-width: 1140px) {
		padding-bottom: 44%;
	}

	@media (max-width: 991px) {
		padding-bottom: 70%;
		width: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.person_content {
	position: relative;
	flex: 0 1 55%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */

	@media (max-width: 1300px) {
		font-size: 16px;
	}

	@media (max-width: 560px) {
		padding: 0px 25px;
	}
}

.quotes {
	margin-bottom: 30px;

	@media (max-width: 991px) {
		margin-bottom: 8px;
	}

	& svg {
		@media (max-width: 1300px) {
			width: 60px;
			height: 60px;
		}

		@media (max-width: 991px) {
			width: 38px;
			height: 38px;
		}
	}
}

.desc_body {
	@media (max-width: 991px) {
		height: 110px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 560px) {
		height: 154px;
	}
}

.person_desc {
	font-weight: 400;
}

.person_name {
	font-weight: 600;
	margin: 20px 0px 25px 0px;

	@media (max-width: 991px) {
		margin: 16px 0px 20px 0px;
	}
}

.btn_read {
	border-radius: 100px;
	outline: 1px solid #588cc4;
	background: #588cc4;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fefefe;
	font-size: 18px;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	padding: 12px 88px;
	position: absolute;
	bottom: 0;
	right: 1px;
	transition: all 0.4s ease-in-out;

	@media (max-width: 991px) {
		position: static;
		align-self: center;
		width: 70%;
		padding: 12px 0px;
	}

	@media (max-width: 560px) {
		font-size: 16px;
		padding: 14px 0px;		
		width: 95%;
	}

	&:hover {
		color: #588cc4;
		background-color: #fefefe;
		transition: all 0.4s ease-in-out;
	}
}
