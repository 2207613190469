.news_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 45px 0;
	padding: 20px 0;
	gap: 20px;
	font-size: 20px;
	font-weight: 400;
line-height: 140%;
	user-select: none;

	&__num {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		padding: 4px;
		border-bottom: 2px solid transparent;
		cursor: pointer;

		&_active {
			transition: all 0.3s ease;
			color: #588cc4;
			font-weight: 700;
			border-bottom: 2px solid #588cc4;
		}
	}
}

.arrow_left,
.arrow_right {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid #28609d;
	transition: all 0.3s ease-in-out;

	&:hover {
		border: 1px solid #588cc4;
		background-color: #588cc4;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		svg path {
			fill: #fefefe;
			transition: all 0.3s ease-in-out;
		}
	}

	& {
		svg path {
			transition: all 0.3s ease-in-out;
		}
	}
}

.arrow_left {
	margin-right: 10px;

	& svg {
		transform: rotate(180deg);
	}
}

.arrow_right {
	margin-left: 10px;
}
