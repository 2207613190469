.wrapperStory {
	flex: 1;
	display: flex;
	justify-content: center;
	background-color: #ddf4fe;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 110px;
		background-image: url("../../../public/images/stories/bg_up.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 991px) {
			height: 40px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 110px;
		background-image: url("../../../public/images/stories/bg_down.png");
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 991px) {
			height: 40px;
		}
	}
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
	padding: 70px 185px;

	@media (max-width: 1300px) {
		padding: 70px 120px;
	}

	@media (max-width: 1140px) {
		padding: 70px 80px;
	}

	@media (max-width: 991px) {
		padding: 40px 60px;
	}

	@media (max-width: 768px) {
		padding: 40px 25px;
	}
}
