.wrapperStories {
	display: flex;
	justify-content: center;
}

.container {
	max-width: 1440px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 60px;
	padding: 100px 75px;

	@media (min-width: 992px) {
		background-image: url('../../../public/images/stories/bg_story.png');
		background-repeat: no-repeat;
		background-position: 92% 11%;
		background-size: contain;
	}

	@media (max-width: 1300px) {
		padding: 100px 40px;
	}

	@media (max-width: 1140px) {
		padding: 100px 25px;
	}

	@media (max-width: 991px) {
		padding: 56px 25px;
		gap: 40px;
	}

	@media (max-width: 560px) {
		padding: 56px 0px;
	}
}

.box_title {
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;

	@media (max-width: 560px) {
		padding: 0px 25px;
	}
}

.title {
	color: #588cc4;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: 560px) {
		font-size: 14px;
	}
}

.subtitle {
	color: #03071e;
	font-family: "Rubik";
	font-size: 36px;
	font-weight: 500;
	line-height: 120%; /* 43.2px */

	@media (max-width: 768px) {
		font-size: 30px;
	}

	@media (max-width: 560px) {
		font-size: 22px;
	}
}

.box_stories{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}