.member_body {
	flex: 0 1 31.78%;
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	@media (max-width: 1439px) {
		flex: 0 1 31.2%;
	}

	&.active {
		transition: all 0.3s ease-in-out;
	}

	&.active:hover .image {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		transition: all 0.3s ease-in-out;
	}

	&.active:hover .image:after {
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
		transition: all 0.3s ease-in-out;
	}

	&.active:hover .info_box {
		height: 25%;
		transition: all 0.3s ease-in-out;
	}

	&.size {
		max-width: 500px;
	}
}

.image {
	position: relative;
	padding-bottom: 71%;
	transition: all 0.3s ease-in-out;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0);
		transition: all 0.3s ease-in-out;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.member_content {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 320px;
	padding: 28px 20px;
	color: #03071e;
	background-image: url("../../../../../public/images/team/bg_member.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}

.member_btn {
	position: absolute;
	top: 28px;
	right: 20px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover svg {
		transform: rotate(90deg) scale(1.2);
		transition: all 0.3s ease-in-out;
	}

	& svg {
		transition: all 0.3s ease-in-out;
	}
}

.member_name {
	font-size: 20px;
	font-weight: 700;
	line-height: 140%; /* 28px */
}

.member_position {
	font-size: 18px;
	font-weight: 600;
	line-height: 140%;
	margin: 4px 0px 20px 0px;
}

.member_desc {
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
}

.info_box {
	display: flex;
	flex-direction: column;
	gap: 4px;
	line-height: 140%;
	color: #fefefe;
	position: absolute;
	left: 16px;
	bottom: 0;
	width: 95%;
	height: 0;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.info_name {
	font-size: 20px;
	font-weight: 700;
}

.info_position {
	font-size: 18px;
	font-weight: 600;
}
